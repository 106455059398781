import * as React from "react";
import * as styles from "./SectionTitle.module.scss";
import parse from "html-react-parser";
import Container from "../../components/container/container";

const SectionTitle = ({ text, textMaxWidth }) => {
  return (
    <section className={styles.section}>
      <Container>
        <h2 style={{ maxWidth: textMaxWidth }}>{parse(text)}</h2>
      </Container>
    </section>
  );
};

export default SectionTitle;
