import * as React from "react";
import * as styles from "./BlocksWithIconsThree.module.scss";
import Container from "../../components/container/container";
import parse from "html-react-parser";
import SectionPatternDark from "../../svg/section-pattern-dark.svg";

const BlocksWithIconsThree = ({
  title,
  text,
  items,
  align,
  background,
  titleMaxWidth,
  textMaxWidth,
  button,
}) => {
  return (
    <section className={styles.blocks}>
      {background && (
        <div className={styles.sectionPattern}>
          <SectionPatternDark />
        </div>
      )}
      <Container>
        {title && (
          <h2 style={{ textAlign: align, maxWidth: titleMaxWidth }}>
            {parse(title)}
          </h2>
        )}
        {text && (
          <p
            className={styles.blocksText}
            style={{ textAlign: align, maxWidth: textMaxWidth }}
          >
            {text}
          </p>
        )}
        <div className={styles.blocksContainer}>
          <div className={styles.blocksWrap}>
            {items &&
              items.map((item, index) => (
                <div className={styles.blocksItem} key={index}>
                  <div className={styles.blocksItemIcon}>{item.icon}</div>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </div>
              ))}
          </div>
        </div>
        {button && <div className={styles.blocksButton}>{button}</div>}
      </Container>
    </section>
  );
};

export default BlocksWithIconsThree;
