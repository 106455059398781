import * as React from "react";
import Layout from "../components/layout/layout";
import LottieAnimation from "../components/lootie/Lottie";
import Image from "../images/product-hero.jpg";
import Hero from "../sections/hero/hero";
import Products from "../sections/products/Products";
import Audit from "../sections/audit/Audit";
import Committe from "../sections/committee/Committe";
import SectionTitle from "../sections/sectionTitle/SectionTitle";
import BlocksWithIconsThree from "../sections/blocksWithIconsThree/BlocksWithIconsThree";
import CustomLink from "../components/customLink/CustomLink";
import FadeIn from "../components/fade/fade";

import HeroVideo from "../videos/product.mp4";

import FinderJson from "../json/Finders.json";
import StakersJson from "../json/Stakers.json";
import DesisionMakingJson from "../json/Decision_Making.json";

const sectionWithThreeBlocksData = {
  title: "<span>Lossless</span> Protocol",
  text: "Lossless protocol incorporates a new layer of blockchain security, ensuring that fraudulent transactions are identified, frozen, and recovered with the help of our active community of Finders and Stakers.",
  textMaxWidth: "78.9rem",
  align: "left",
  items: [
    {
      title: "Finders",
      text: "Finders stake $LSS to flag up fraudulent transactions they suspect originate from exploits, malicious activity, or social engineering. Reports freeze the addresses of allegedly malicious actors for 24-48 hours.",
      icon: <LottieAnimation animationJson={FinderJson} />,
    },
    {
      title: "Stakers",
      text: "Community Stakers put their weight behind the reports they deem credible, expressing their vote of confidence through staking $LSS to increase the report’s visibility.",

      icon: <LottieAnimation animationJson={StakersJson} />,
    },
    {
      title: "Decision Making",
      text: "Lossless Decision-Making Body employs proprietary exploit identification tools and fraud parameters to make rulings on open reports. If a transaction is reversed, Finders and Stakers are awarded a fee for every verified discovery.",
      icon: <LottieAnimation animationJson={DesisionMakingJson} />,
    },
  ],
  button: (
    <CustomLink
      text="Documentation"
      size="small"
      type="blue"
      url="https://lossless-cash.gitbook.io/lossless/technical-reference/hack-mitigation-protocol"
      blank={true}
    />
  ),
};

const ProductPage = () => {
  return (
    <Layout pageTitle="Product - Lossless">
      <Hero
        title="Fail-Safe Your Web3 Protocol"
        text="Lossless brings unrivalled exploit identification and mitigation tools, designed to foolproof Web3 protocols and their communities from malicious activity. "
        // backgroundUrl={Image}
        titleMaxWidth="71.9rem"
        textMaxWidth="63.8rem"
        video={HeroVideo}
      />
      <FadeIn>
        <BlocksWithIconsThree
          items={sectionWithThreeBlocksData.items}
          align={sectionWithThreeBlocksData.align}
          title={sectionWithThreeBlocksData.title}
          text={sectionWithThreeBlocksData.text}
          button={sectionWithThreeBlocksData.button}
          background={sectionWithThreeBlocksData.background}
          textMaxWidth={sectionWithThreeBlocksData.textMaxWidth}
        />
      </FadeIn>
      <FadeIn>
        <SectionTitle
          text="Frozen transactions are reviewed by the <span>Lossless Decision-Making Body,</span> comprised of reputable cybersecurity, blockchain, and AML specialists. By providing unbiased rulings, <span>Lossless</span> safeguards the integrity of the exploit investigation process."
          textMaxWidth="96.8rem"
        />
      </FadeIn>
      <FadeIn>
        <Committe />
      </FadeIn>
      <FadeIn>
        <Products />
      </FadeIn>
      <FadeIn>
        <Audit />
      </FadeIn>
    </Layout>
  );
};

export default ProductPage;
