import * as React from "react";
import * as styles from "./Committe.module.scss";
import Container from "../../components/container/container";
import CustomLink from "../../components/customLink/CustomLink";
import LinkedinLogo from "../../svg/linkedin.svg";
import { StaticImage } from "gatsby-plugin-image";
import SectionPatternDark from "../../svg/section-pattern-commitee.svg";

const committeMembers = [
  {
    fullName: "Alexey Koloskov",
    position: "CEO of Orion Protocol",
    image: (
      <StaticImage
        src="../../images/committee/alexey.png"
        alt="Alexey Koloskov"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/alexey-koloskov/",
      },
    ],
  },
  {
    fullName: "Roman Semenov",
    position: "Co-Founder of Tornado Cash ",
    image: (
      <StaticImage src="../../images/committee/roman.png" alt="Roman Semenov" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/semenov-roman/",
      },
    ],
  },
  {
    fullName: "Matthijs van Driel",
    position: "Founder of Magnus Capital ",
    image: (
      <StaticImage
        src="../../images/committee/matthijs.png"
        alt="Matthijs van Driel"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/matthijsvandriel/",
      },
    ],
  },
  {
    fullName: "Harry Denley",
    position: "Security Researcher from MetaMasK",
    image: (
      <StaticImage src="../../images/committee/harry.png" alt="Harry Denley" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/harry-denley-36087354/",
      },
    ],
  },
  {
    fullName: "Tiago Martins",
    position: "CTO and Co-Founder of Polkastarter",
    image: (
      <StaticImage src="../../images/committee/tiago.png" alt="Tiago Martins" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/temmartins/",
      },
    ],
  },
  {
    fullName: "Dan Thomson",
    position: "CTO and Co-Founder of Polkastarter",
    image: (
      <StaticImage src="../../images/committee/dan.png" alt="Dan Thomson" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/vagrantcrypto/",
      },
    ],
  },
  {
    fullName: "Rex Hygate",
    position: "Security Researcher from MetaMasK",
    image: (
      <StaticImage src="../../images/committee/rex.png" alt="Rex Hygate" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/rexhygate/",
      },
    ],
  },
  {
    fullName: "Adam Bém",
    position: "Co-Founder & COO at Victoria VR ",
    image: <StaticImage src="../../images/committee/adam.png" alt="Adam Bém" />,
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/adam-bem/",
      },
    ],
  },
  {
    fullName: "Jacek Czarnecki",
    position: "Former Global Legal Counsel at the Maker Foundation (MakerDAO)",
    image: (
      <StaticImage
        src="../../images/committee/jacek.png"
        alt="Jacek Czarnecki"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/jacekczarnecki/",
      },
    ],
  },
];

const Committe = () => {
  return (
    <section className={styles.committee}>
      <div className={styles.sectionPattern}>
        <SectionPatternDark />
      </div>
      <Container>
        <div>
          <h2>Security Committee</h2>
          <div className={styles.committeeMembers}>
            {committeMembers.map((member, index) => (
              <div className={styles.committeeMember} key={index}>
                <div className={styles.committeeMemberImage}>
                  {member.image}
                </div>
                <div>
                  <div className={styles.committeeMemberBottom}>
                    <h3 className={styles.committeeMemberName}>
                      {member.fullName}
                    </h3>
                    {typeof member.socials !== "undefined" && (
                      <div className={styles.committeeMemberSocials}>
                        {member.socials.map((social, indexChild) => (
                          <a
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={indexChild}
                          >
                            {social.icon}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  <p className={styles.committeeMemberLabel}>
                    {member.position}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.committeeButton}>
            <CustomLink
              text="Learn more"
              type="blur"
              url="https://losslesscash.medium.com/introducing-the-security-committee-alexey-koloskov-ceo-of-orion-protocol-3641db033b89"
              size="small"
              blank={true}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Committe;
