import * as React from "react";
import * as styles from "./Audit.module.scss";
import Container from "../../components/container/container";
import CustomLink from "../../components/customLink/CustomLink";

import AbdkLogo from "../../images/audits/abdk.png";
import HackenLogo from "../../images/audits/hacken.png";
import CertikLogo from "../../images/audits/certik.png";

const audits = [
  {
    title: "Core protocol, Vault protection, LERC-20",
    logo: AbdkLogo,
    button: (
      <CustomLink
        text="Audit"
        type="bright"
        url="https://github.com/abdk-consulting/audits/tree/main/lossless"
        size="small"
        blank={true}
      />
    ),
  },
  {
    title: "LERC-20",
    logo: HackenLogo,
    button: (
      <CustomLink
        text="Audit"
        type="bright"
        url="https://hacken.io/audits/#lossless"
        size="small"
        blank={true}
      />
    ),
  },
  {
    title: "Vault protection, LERC-20",
    logo: CertikLogo,
    button: (
      <CustomLink
        text="Audit"
        type="bright"
        url=" https://www.certik.com/projects/lossless"
        size="small"
        blank={true}
      />
    ),
  },
];

const Audit = () => {
  return (
    <section className={styles.audit}>
      <Container>
        <div className={styles.auditContainer}>
          <h2>Audited smart contracts</h2>
          <div className={styles.auditWrap}>
            {audits.map((audit, index) => (
              <div className={styles.auditItem} key={index}>
                <h3>{audit.title}</h3>
                <div className={styles.auditItemRight}>
                  <img src={audit.logo} alt="" />
                  {audit.button}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Audit;
