// extracted by mini-css-extract-plugin
export var committee = "Committe-module--committee--8NOEg";
export var committeeButton = "Committe-module--committeeButton--qccbI";
export var committeeMember = "Committe-module--committee-member--v7Hjs";
export var committeeMemberBottom = "Committe-module--committee-member-bottom--BT3-B";
export var committeeMemberImage = "Committe-module--committee-member-image--tm2EN";
export var committeeMemberLabel = "Committe-module--committee-member-label--iVAcH";
export var committeeMemberName = "Committe-module--committee-member-name--PqwWL";
export var committeeMemberSocials = "Committe-module--committee-member-socials--jJL-s";
export var committeeMembers = "Committe-module--committee-members--WxJ+5";
export var sectionPattern = "Committe-module--section-pattern--6RLKq";